<template>
  <div class="card p-2">
    <b-row class="mb-2">
      <b-col
        col
        lg="6"
      >
        <h4> Cadastro Checklists </h4>
        <h6>Projeto: {{ getProjeto.descricao }}</h6>

      </b-col>
    </b-row>
    <b-form>
      <b-row>
        <b-col cols="6">
          <b-form-group
            label="Descrição Checklist"
            label-for="descricao"
          >
            <b-form-input
              id="descricao"
              v-model="checklist.descricao"
              placeholder="Informe a descrição do checklist"
            />
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group
            label="Localizações do Projeto"
            label-for="localizacoes"
          >
            <select-com-pesquisa
              ref="localizacoes"
              :url="urlLocalizacoes"
              placeholder="Escolha as localizações"
              :multiple="false"
              :disabled="false"
              :itens-para-edicao="[]"
              @selecionados="tratarEventoLocalizacoesSelecionadas"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>

        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Escolha as disciplinas"
            label-for="disciplinas"
          >
            <select-com-pesquisa
              ref="selectDisciplinas"
              url="/disciplinas"
              placeholder="Escolha as disciplinas"
              :multiple="false"
              :disabled="false"
              :itens-para-edicao="[]"
              @selecionados="tratarEventoDisciplinasSelecionadas"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="8"
        >
          <b-form-group
            label="Escolha a subdisciplina"
            label-for="subdisciplinas"
          >

            <select-com-pesquisa
              ref="selectSubdisciplinas"
              :url="urlSubdisciplinas"
              placeholder="Escolha a subdisciplina"
              :multiple="false"
              :disabled="exibirSelectSubdisciplinas"
              :itens-para-edicao="[]"
              @selecionados="tratarEventoSubdisciplinasSelecionadas"
            />

          </b-form-group>

        </b-col>

      </b-row>
      <div
        v-if="loadTarefas"
        class="d-flex justify-content-center my-1"
      >
        <b-spinner
          variant="secondary"
          small
          type="grow"
        />
        <b-spinner
          class="mx-1"
          variant="secondary"
          small
          type="grow"
        />
        <b-spinner
          variant="secondary"
          small
          type="grow"
        />
      </div>
      <hr>
      <b-row v-if="possuiTarefasPesquisadas">

        <b-col cols="12">
          <h5
            v-if="grupotarefas.length"
            class="mb-1"
          >
            Escolha um grupo de tarefas
          </h5>
          <div class="d-flex">
            <b-form-radio
              v-for="grupo in grupotarefas"
              :key="grupo.id"
              v-model="grupoEscolhido"
              name="grupo-tarefas"
              :value="grupo"
              class="mb-2 mr-2"
            >
              {{ grupo.nome }}
            </b-form-radio>
          </div>
          <div class="d-flex justify-content-between">
            <h5>Escolha abaixo as tarefas para o checklist:</h5>
            <b-button
              variant="outline-primary"
              class="mb-1 mb-sm-0 mr-0 ml-sm-1"
              @click="selecionarTodas"
            >Selecionar todas
            </b-button>
          </div>

          <b-form-group>

            <b-form-checkbox-group
              v-model="tarefasSelecionadas"
              :options="tarefasFiltradas"
              value-field="value"
              text-field="text"
              formato-field="formato"
              class="listaTarefas"
            />
          </b-form-group>

          <b-col class="text-right pr-0 mt-4">
            <b-button
              :disabled="!possuiTarefasSelecionadas"
              type="button"
              variant="outline-primary"
              class="mb-1 mb-sm-0 mr-0 ml-sm-1"
              @click="adicionarTarefasTabela()"
            >
              Adicionar Tarefas
            </b-button>
          </b-col>
        </b-col>
      </b-row>
      <hr>
      <b-row v-if="checklist.tarefas.length">
        <b-col
          cols="12"
          class="my-1"
        >
          <h5>
            Tarefas do checkilist ({{ checklist.tarefas.length }})
          </h5>
          <small>Escolha a ordem de execução delas.</small>
        </b-col>

        <b-col
          cols="12"
          class="mb-2"
        >

          <draggable
            v-model="checklist.tarefas"
            class="list-group list-group-flush cursor-move"
            tag="ul"
          >
            <transition-group
              type="transition"
              name="flip-list"
            >
              <b-list-group-item
                v-for="listItem in checklist.tarefas"
                :key="listItem.value"
                tag="li"
              >
                <div class="d-flex justify-content-between">

                  <div class="ml-25 d-flex align-items-center">
                    <feather-icon icon="AlignJustifyIcon" />

                    <b-card-text class="mb-0 ml-1 font-size-9">
                      {{ listItem.text }}
                    </b-card-text>

                  </div>
                  <b-button
                    variant="gradient-danger"
                    size="sm"
                    class="btn-icon rounded-circle"
                    @click="removerTarefaLista(listItem)"
                  >
                    <feather-icon icon="TrashIcon" />
                  </b-button>
                </div>
              </b-list-group-item>
            </transition-group>
          </draggable>

        </b-col>
      </b-row>
    </b-form>
    <b-col class="text-right pr-0">
      <b-button
        variant="outline-secondary"
        type="button"
        @click="voltarParaLista()"
      >
        Voltar
      </b-button>
      <b-button
        type="button"
        variant="success"
        class="mb-1 mb-sm-0 mr-0 ml-sm-1"
        @click="salvarCheckList()"
      >
        Salvar
      </b-button>
    </b-col>
  </div>
</template>

<script>
import {
  BButton,
  BCardText,
  BCol,
  BForm,
  BFormCheckboxGroup,
  BFormGroup,
  BFormInput,
  BFormRadio,
  BListGroupItem,
  BRow,
  BSpinner,
} from 'bootstrap-vue'
import { onUnmounted } from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'
import axios from '@axios'
import draggable from 'vuedraggable'
import projetosStoreModule from '@/views/apps/projetos/projetosStoreModule'
import router from '@/router'
import SelectComPesquisa from '@/views/components/componentesPensou/SelectComPesquisa.vue'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'

export default {

  components: {
    BButton,
    BFormRadio,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    SelectComPesquisa,
    BFormCheckboxGroup,
    BSpinner,
    draggable,

    BListGroupItem,
    BCardText,

  },
  setup(props) {
    const PROJETOS_APP_STORE_MODULE_NAME = 'app-projetos'

    // Register module
    if (!store.hasModule(PROJETOS_APP_STORE_MODULE_NAME)) store.registerModule(PROJETOS_APP_STORE_MODULE_NAME, projetosStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(PROJETOS_APP_STORE_MODULE_NAME)) store.unregisterModule(PROJETOS_APP_STORE_MODULE_NAME)
    })
    const toast = useToast()

    store.dispatch('app-projetos/show', router.history.current.params.id)

    return {
      toast,
    }
  },
  data() {
    return {

      url: {
        idDisciplinas: null,
        idSubdisciplinas: null,

      },
      loadTarefas: false,
      filtroData: {
        disciplinas: {},
        subdisciplinas: {},
      },
      localizacoes: [],
      grupotarefas: [],
      grupoEscolhido: null,
      tarefasFiltradas: [],
      tarefasSelecionadas: [],
      fields: [
        {
          key: 'text',
          sortable: true,
          label: 'Tarefa',
        },
        { key: 'Ações' },
      ],
      checklist: {
        tarefas: [],
        descricao: '',
        localizacoes: [],
      },

    }
  },
  computed: {
    possuiTarefasPesquisadas() {
      return this.tarefasFiltradas.length
    },
    possuiTarefasSelecionadas() {
      return this.tarefasSelecionadas.length > 0
    },
    exibirSelectSubdisciplinas() {
      return this.filtroData.disciplinas && Object.keys(this.filtroData.disciplinas).length === 0
    },

    urlSubdisciplinas() {
      return `/subdisciplinas?disciplina=${this.url.idDisciplinas}`
    },
    getProjeto() {
      return this.$store.state['app-projetos'].projeto
    },
    urlLocalizacoes() {
      return `localizacoes?projeto=${router.history.current.params.id}`
    },

  },
  watch: {
    grupoEscolhido(val) {
      this.tarefasSelecionadas = val.tarefas.map(item => item.uuid)
    },

  },

  methods: {
    async getTarefas() {
      this.loadTarefas = true
      this.tarefasFiltradas = []

      const subdisciplinas = this.filtroData.subdisciplinas.id

      const response = await store.dispatch('app-projetos/pesquisaTarefas', subdisciplinas)
      this.loadTarefas = false

      this.tarefasFiltradas = response.data.map(item => ({
        text: item.descricao,
        value: item.uuid,
      }))
      this.tarefasSelecionadas = response.data.filter(item => item.sub_disciplinas.filter(sub => sub.pivot.favorito).length > 0)
        .map(item => (item.uuid))
    },
    async getGruposSub(idSub) {
      this.loadTarefas = true
      this.grupotarefas = []
      this.grupoEscolhido = null
      const response = await store.dispatch('app-projetos/getGrupoTarefas', idSub)
      this.loadTarefas = false
      this.grupotarefas = response.data
    },
    adicionarTarefasTabela() {
      const uuids = this.tarefasSelecionadas
      const arrayDeObjetos = this.tarefasFiltradas
      const arrayOriginal = this.checklist.tarefas
      this.tarefasFiltradas = []
      this.$refs.selectDisciplinas.resetarConteudo()
      this.$refs.selectSubdisciplinas.resetarConteudo()

      // Filtrar objetos encontrados com base nos UUIDs selecionados
      const objetosEncontrados = arrayDeObjetos.filter(objeto => uuids.includes(objeto.value))

      // Filtrar objetos diferentes entre objetosEncontrados e arrayOriginal
      const arrayDiferente = objetosEncontrados.filter(item2 => {
        const itemEncontrado = arrayOriginal.find(item1 => item1.value === item2.value)

        if (!itemEncontrado) {
          this.toast({
            component: ToastificationContent,
            props: {
              title: `O item ${item2.text} foi adicionado`,
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          return true // O item não foi encontrado em arrayOriginal
        }
        this.toast({
          component: ToastificationContent,
          props: {
            title: `O item ${item2.text} já está na lista`,
            icon: 'InfoIcon',
            variant: 'warning',
          },
        })

        return false
      })

      // Adicionar objetos diferentes ao arrayOriginal
      this.checklist.tarefas.push(...arrayDiferente)

      this.tarefasSelecionadas = []
    },
    tratarEventoLocalizacoesSelecionadas(itens) {
      this.checklist.localizacoes = itens
    },
    tratarEventoDisciplinasSelecionadas(itens) {
      this.filtroData.disciplinas = itens || {}
      this.url.idDisciplinas = itens?.id

      this.$refs.selectSubdisciplinas.resetarConteudo()
    },
    tratarEventoSubdisciplinasSelecionadas(itens) {
      if (itens) {
        this.filtroData.subdisciplinas = itens || {}
        this.url.idSubdisciplinas = itens?.id
        this.checklist.descricao = itens && itens.name
        this.getGruposSub(itens.id)
        this.getTarefas()
      } else {
        this.tarefasFiltradas = []
      }
    },

    removerTarefaLista(param) {
      const uuidParaRemover = param.value // Substitua pelo UUID do item que deseja remover

      // Encontrar o índice do item com o UUID correspondente
      const indexParaRemover = this.checklist.tarefas.findIndex(item => item.value === uuidParaRemover)

      if (indexParaRemover !== -1) {
        // Se o item for encontrado (índice diferente de -1), remova-o do array usando splice
        this.checklist.tarefas.splice(indexParaRemover, 1)
      }
      this.toast({
        component: ToastificationContent,
        props: {
          title: `O item ${param.text} foi removido.`,
          icon: 'TrashIcon',
          variant: 'danger',
        },
      })
    },

    async salvarCheckList() {
      const contexto = this

      if (this.validarChecklist(this.checklist)) {
        return
      }

      const formData = new FormData()

      this.checklist.projeto_id = this.getProjeto.uuid

      formData.append('localizacao_id', this.checklist.localizacoes.id)
      formData.append('descricao', this.checklist.descricao)
      formData.append('projeto_id', this.checklist.projeto_id)

      if (this.checklist.localizacoes.length === 0) {
        formData.append('localizacao_id', this.getProjeto.localizacoes[0].uuid)
      }

      const listaTarefas = this.checklist.tarefas.map((item, index) => ({
        id: item.value,
        ordem: index,
      }))

      listaTarefas.forEach((item, index) => {
        formData.append(`tarefas[${index}][id]`, item.id)
        formData.append(`tarefas[${index}][ordem]`, item.ordem)
      })

      axios
        .post('/checklists', formData)
        .then(() => {
          contexto.toast({
            component: ToastificationContent,
            props: {
              title: 'Checklist criado com sucesso.',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          contexto.voltarParaLista()
        })
        .catch(error => {
          contexto.toast({
            component: ToastificationContent,
            props: {
              title: 'Verifique se os campos estão corretos.',
              icon: 'CheckIcon',
              variant: 'danger',
            },
          })
        })
    },
    selecionarTodas() {
      this.tarefasSelecionadas = this.tarefasFiltradas.map(item => item.value)
    },
    // eslint-disable-next-line consistent-return
    validarChecklist(checklist) {
      if (!checklist.descricao) {
        this.toast({
          component: ToastificationContent,
          props: {
            title: 'Informe uma descrição',
            icon: 'InfoIcon',
            variant: 'danger',
          },
        })
        return true
      }
      if (!checklist.tarefas.length > 0) {
        this.toast({
          component: ToastificationContent,
          props: {
            title: 'Nenhuma tarefa inserida',
            icon: 'InfoIcon',
            variant: 'danger',
          },
        })
        return true
      }
    },
    voltarParaLista() {
      router.push(`/projetos/${this.$route.params.id}/checklists`)
    },
  },

}
</script>
<style lang="scss">
.listaTarefas {
  margin: 16px 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;

  .custom-control-label {
    font-size: 0.8em;
  }
}

.list-group-item {
  transition: all 1s;
  cursor: move;
}
</style>
